import React from "react";
import { Link } from "@chakra-ui/layout";
import { useColorModeValue } from "@chakra-ui/color-mode";

const SidebarNavLink = (props: any) => {
    const hoverBgColor = useColorModeValue("primary.500", "primary.600");
    const hoverTextColor = useColorModeValue("primary.100", "primary.200");

    return (
        <Link
            href={props.href}
            minW="15vw"
            px={3}
            py={2}
            rounded={"md"}
            fontWeight="bold"
            _hover={{
                textDecoration: "none",
                color: hoverTextColor,
                bg: hoverBgColor,
            }}
            _focus={{
                ring: "2px",
                ringColor: "primary.500",
            }}
            onClick={props.drawerClose}
        >
            {props.children}
        </Link>
    );
};

export default SidebarNavLink;
